import React, { useState } from "react";
import { Link } from "gatsby";
import slugify from "slugify";
import { Fade } from "react-reveal";
import { FaSearch } from "react-icons/fa";
import BlogCards from "./BlogCards";

const Header = ({ posts }) => {
  const [search, setSearch] = useState(null);
  const replace = posts.filter(({ node }) => node.id !== posts[0]?.node?.id);

  const filteredData =
    search !== null
      ? replace.filter(({ node }) =>
          node.title.toLowerCase().includes(search.toLowerCase())
        )
      : replace;
  const postSlug = posts[0]?.node?.slug;

  return (
    <div className="pt-[75px] pb-[120px] w-full bg-bgBlack">
      <div className="max-w-7xl w-full mx-auto px-4 md:px-10">
        <div className="w-full text-center mb-[42px]">
          <Fade bottom>
            <h2 className="text-white text-[32px] md:text-[48px] font-medium">
              Explore Happen
            </h2>
          </Fade>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 mb-14">
          <div className="flex self-center">
            <Link
              to={`/explore/${slugify(postSlug ? postSlug : "", {
                lower: true,
              })}`}
            >
              <div className="w-full overflow-hidden">
                <img
                  className="w-full md:h-[480px] object-cover hover:scale-105 transition-all duration-300 rounded-lg  scale-100"
                  src={posts[0]?.node?.image?.url}
                  alt="img"
                />
              </div>
            </Link>
          </div>
          <Link
            to={`/explore/${slugify(postSlug ? postSlug : "", {
              lower: true,
            })}`}
          >
            <div className="flex flex-col blog-shadow rounded-[10px] cursor-pointer overflow-hidden max-w-[550px]">
              <div className="py-5 md:px-[30px] w-full">
                <span className=" text-[#7D7880] text-base font-medium block mb-[24px]">
                  {posts[0]?.node?.date}
                </span>
                <h3 className="text-white text-[28px] mb-[23px] font-semibold">
                  {posts[0]?.node?.title}
                </h3>
                <p className="mb-[35px]  text-[#7D7880] text-[18px] ">
                  {posts[0]?.node?.short_description}
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex justify-center relative  mb-[120px]">
          <input
            type={`search`}
            placeholder="Search articles"
            className="text-[#A8A5A9] bg-bgBlack  focus-within:outline-none w-full border border-[#7D7880] placeholder-[#7D7880] rounded-md h-[45px] pl-[36px]  text-[18px]"
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="absolute left-3 top-3.5">
            <FaSearch className="text-[#A8A5A9]" />
          </span>
        </div>
        <div>
          <BlogCards posts={filteredData} />
        </div>
      </div>
    </div>
  );
};

export default Header;
