import React from "react";
import { Link } from "gatsby";
import slugify from "slugify";
import { MdKeyboardArrowRight } from "react-icons/md";
const BlogCards = ({ posts }) => {
  return (
    <div className=" grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 gap-10 ">
      {posts.map((post) => (
        <div key={post.node.id} className="  mb-6 rounded-[10px]  text-left">
          <div>
            <img
              src={post.node.image.url}
              alt=""
              className="rounded-t-[11px] w-full min-h-[280px] object-cover"
            />
            <div className=" p-6 flex flex-col justify-between gap-y-[31px] bg-white rounded-b-lg">
              <div className="font-medium text-lg leading-6 space-y-1 tooltip">
                <h3 className="text-black text-center font-semibold text-lg mb-[30px]">
                  {post.node.title}
                  <span className="tooltiptext">{post.node.title}</span>
                </h3>
              </div>
              <div className="flex justify-center">
                <div>
                  <Link
                    to={`/explore/${slugify(post.node.slug, {
                      lower: true,
                    })}`}
                  >
                    <span className="text-sm uppercase flex gap-1 items-center font-semibold text-secondary">
                      Read More
                      <span>
                        <MdKeyboardArrowRight className="text-lg" />
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogCards;
