import React from "react";
import Index from "../views/Blogs";
import Layout from "../components/Layout/Layout";


const BlogPage = () => {

  return (
    <div className="bg-bgBlack">
      <Layout>
        <Index />
      </Layout>
    </div>
  );
};

export default BlogPage;
