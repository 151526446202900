import React, { useEffect, useContext } from "react";
import { AppContext } from "../../components/Layout/context";
import Header from "./Header";
import SignUp from "../Home/SignUp";
import SEO from "../../components/SEO"
import preview from "../../images/preview.jpg"

import { useStaticQuery, graphql } from "gatsby";
const Index = () => {
  const { lan, setLang } = useContext(AppContext);

  useEffect(() => {
    const languagekey = localStorage.getItem("lankey");
    if (languagekey === null) {
      localStorage.setItem("lankey", "en");
      setLang("en");
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allStrapiPost(sort: {fields: explore_order}, filter: {explore_order: {ne: 0}}) {
        edges {
          node {
            locale
            id
            title
            slug
            body
            explore_order
            short_description
            author
            date(formatString: "DD-MM-YYYY")
            image {
              url
              alternativeText
            }
          }
        }
      }
    }
  `);
  const posts = data.allStrapiPost?.edges;
  const filteredData = posts.filter(({ node }) => node.locale.includes(lan));

  return (
    <div>
      <SEO title="Happen Space | Blog"/>
      <Header posts={filteredData} />
      <div id="Signup">
        <SignUp />
      </div>
    </div>
  );
};

export default Index;
